class Gen_profit {

  constructor(retail_price, cost_price, delivery, category_std, category_rs, category_bonus) {
    this.retail_price = retail_price;
    this.cost_price = cost_price
    this.delivery = delivery
    this.category_std = category_std
    this.category_rs = category_rs
    this.category_bonus = category_bonus
  }

}


class Gen_kaspi_std extends Gen_profit {
  get_kaspi_std_tarif() {
    return ((this.retail_price * this.category_std) / 100);
  }


  std_profit_without_nds() {
    return this.retail_price - this.cost_price - this.get_kaspi_std_tarif()
  }


  std_nds() {
    return (this.std_profit_without_nds() * 12) / 112
  }

  service_product() {
    return this.retail_price * 0.01
  }

  std_profit_with_nds_and_delivery_and_service() {
    var std_profit_with_nds_and_delivery_and_service = this.std_profit_without_nds() - this.std_nds() - this.delivery - this.service_product();
    return std_profit_with_nds_and_delivery_and_service;
  }

  std() {
    return (this.std_profit_with_nds_and_delivery_and_service() / this.retail_price) * 100
  }
}


class Gen_kaspi_rs extends Gen_profit {
  get_kaspi_rs_tarif() {
    return ((this.retail_price * this.category_rs) / 100);
  }


  rs_profit_without_nds() {
    return this.retail_price - this.cost_price - this.get_kaspi_rs_tarif()
  }


  rs_nds() {
    return (this.rs_profit_without_nds() * 12) / 112
  }

  service_product() {
    return this.retail_price * 0.01
  }

  rs_profit_with_nds_and_delivery_and_service() {
    var rs_profit_with_nds_and_delivery_and_service = this.rs_profit_without_nds() - this.rs_nds() - this.delivery - this.service_product();
    return rs_profit_with_nds_and_delivery_and_service;
  }

  rs() {
    return (this.rs_profit_with_nds_and_delivery_and_service() / this.retail_price) * 100
  }
}


class Gen_kaspi_bonus extends Gen_profit {
  get_kaspi_bonus_tarif() {
    return ((this.retail_price * this.category_bonus) / 100);
  }


  bonus_profit_without_nds() {
    return this.retail_price - this.cost_price - this.get_kaspi_bonus_tarif()
  }


  bonus_nds() {
    return (this.bonus_profit_without_nds() * 12) / 112
  }

  service_product() {
    return this.retail_price * 0.01
  }

  bonus_profit_with_nds_and_delivery_and_service() {
    var bonus_profit_with_nds_and_delivery_and_service = this.bonus_profit_without_nds() - this.bonus_nds() - this.delivery - this.service_product();
    return bonus_profit_with_nds_and_delivery_and_service;
  }

  bonus() {
    return (this.bonus_profit_with_nds_and_delivery_and_service() / this.cost_price) * 100
  }
}




export function get_tarifs(retail_price, cost_price, delivery, category_std, category_rs, category_bonus) {
  let gen_std = new Gen_kaspi_std(retail_price, cost_price, delivery, category_std, category_rs, category_bonus);
  let std = gen_std.std();

  let gen_rs = new Gen_kaspi_rs(retail_price, cost_price, delivery, category_std, category_rs, category_bonus);
  let rs = gen_rs.rs();

  let gen_bonus = new Gen_kaspi_bonus(retail_price, cost_price, delivery, category_std, category_rs, category_bonus);
  let bonus = gen_bonus.bonus();
  if(delivery == null){
    return [null, null, null]
  }
  return [std.toFixed(1), rs.toFixed(1), bonus.toFixed(1)]
}



export function get_delivery_cost(retail_price, weight) {
  let delivery = 0

  if (weight === 0) {
    return null
  }
  if (retail_price <= 5000) {
    return 0
  }

  if (retail_price <= 15000) {
    delivery = 770
    return delivery
  }

  if (retail_price > 15000 && weight <= 5) {
    delivery = 1200
  }

  if (retail_price > 15000 && weight > 5 && weight <= 15) {
    delivery = 1400
  }

  if (retail_price > 15000 && weight > 15 && weight <= 50) {
    delivery = 3000
  }
  if (weight > 50) {
    delivery = 5400
  }
  return delivery
}