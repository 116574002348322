import { createSlice } from '@reduxjs/toolkit'


const defaultProductConfigState = {
    title: '',
    page: 1,
    forKaspi: null,
    isActiveBot: null,
    manager: null,
    hideFirstPositions: null,
    detailSetting: null,
    firstPosition: null,
    stockDaysMin: null,
    stockDaysMax: null,
    rating: null,
    productWhithoutVideo: null,
    productWhithoutReviews: null,
    discount: null,
    equalPrice: null,
    hasImgReview: null,
    botLogLowThreshold: null,
}

const ProductConfigSlice = createSlice({
    name: 'productConfig',
    initialState: defaultProductConfigState,
    reducers: {
        setProductConfig(state, action) {
            state.title = action.payload.title;
            state.page = action.payload.page;
            state.forKaspi = action.payload.forKaspi;
            state.isActiveBot = action.payload.isActiveBot;
            state.manager = action.payload.manager
            state.rating = action.payload.rating
            state.hideFirstPositions = action.payload.hideFirstPositions
            state.productWhithoutReviews = action.payload.productWhithoutReviews
            state.productWhithoutVideo = action.payload.productWhithoutVideo
            state.stockDaysMin = action.payload.stockDaysMin
            state.stockDaysMax = action.payload.stockDaysMax
            state.superBot = action.payload.superBot
            state.discount = action.payload.discount
            state.equalPrice = action.payload.equalPrice
            state.hasImgReview = action.payload.hasImgReview
            state.botLogLowThreshold = action.payload.botLogLowThreshold
        },
        setProductPageConfig(state, action) {
            state.page = action.payload;
        },
        setSearchText(state, action) {
            state.title = action.payload;
        },
        setDetailSetting(state, action) {
            state.detailSetting = action.payload;
        },
        setFirstPosition(state, action) {
            state.firstPosition = action.payload;
        },
    },
})

export default ProductConfigSlice.reducer
export const {
    setProductConfig,
    setProductPageConfig,
    setSearchText,
    setDetailSetting,
    setFirstPosition
} = ProductConfigSlice.actions